












import { defineComponent, useContext } from '@nuxtjs/composition-api';

/* @ts-ignore */
import fallbackImage from '@/assets/images/Kamera_Express_faded_logo.webp';

export default defineComponent({
  name: 'ImageOrFallback',
  props: {
    src: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: null
    },
    height: {
      type: Number,
      default: null
    },
    loading: {
      type: String,
      default: ''
    }
  },
  setup() {
    const { $config } = useContext();
    const handleBrokenImage = (event) => {
      event.target.src = fallbackImage;
    };

    const mediaUrl = (path) => {
      if (path.includes('https://')) {
        return path;
      }
      return `${$config.mediaBaseURL}${path}`;
    };

    return {
      handleBrokenImage,
      mediaUrl,
      fallbackImage
    };
  }
});
