





import { defineComponent, computed, PropType } from '@nuxtjs/composition-api';
import useUrlFormatter from '~/hooks/useUrlFormatter';

type Link = {
  url: string;
  target: string;
};

export default defineComponent({
  props: {
    link: {
      type: Object as PropType<Link>,
      default: () => ({
        url: '',
        target: ''
      })
    }
  },
  setup(props) {
    const formatUrl = useUrlFormatter();

    const url = computed(() => formatUrl(props.link.url, { default: null }));

    const target = computed(() => props.link.target || '_self');

    return {
      url,
      target
    };
  }
});
