

































import { defineComponent, computed, PropType } from '@nuxtjs/composition-api';
import { getCustomFieldByKey } from '~/helpers/utils';
import useWindowResize from '~/hooks/useResizeWindow';

type BannerData = {
  id: number;
  sequence: number;
  number_of_columns: number;
  description?: string;
  urls?: {
    title: string;
    url: string;
    target: string;
    overlay_text: string;
  }[];
  media: {
    code: string;
    media_path: string;
  }[];
  custom_fields?: {
    key: string;
    value_select: string;
    value_string_area: string;
  }[];
};

export default defineComponent({
  name: 'CategoryHeroBanner',
  props: {
    data: {
      type: Object as PropType<BannerData>,
      required: true
    }
  },
  setup(props) {
    const { isDesktop } = useWindowResize();

    const getCustomField = (field: string) => {
      return getCustomFieldByKey(props.data.custom_fields, field);
    };

    const getMedia = (key: string) => {
      return props.data.media?.find((mediaItem) => mediaItem.code === key)
        ?.media_path;
    };

    const textColor = getCustomField('text_color')?.value_select;
    const overlayText = getCustomField('title')?.value_string;

    const linkData = computed(() => props.data.urls?.[0]);

    const hasOverlay = computed(() =>
      Boolean(getCustomField('black_overlay')?.value_select)
    );

    const subtitleText = computed(
      () => getCustomField('subtitle')?.value_string || ''
    );

    const hasSubtitleBackground = computed(() =>
      Boolean(getCustomField('red_bg_subtitle')?.value_select)
    );

    return {
      isDesktop,
      getCustomField,
      getMedia,
      textColor,
      linkData,
      overlayText,
      hasOverlay,
      subtitleText,
      hasSubtitleBackground
    };
  }
});
